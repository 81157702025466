import { ReactNode, MouseEvent, KeyboardEventHandler, Ref, forwardRef } from 'react';
import NextLink from 'next/link';
import { LinkInfo } from '../../types';
import getAnchorRel from '../Link/getAnchorRel';
import useIsExternalLink from '../Link/useIsExternalLink';

type LinkInfoRendererProps = {
  ariaLabel?: string;
  children: ReactNode;
  className?: string;
  linkInfo: LinkInfo;
  onBlur?: () => void;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  onFocus?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLElement>;
  role?: string;
  tabIndex?: number;
  testId?: string;
  title?: string;
};

const LinkInfoRenderer = (
  { ariaLabel, children, className, linkInfo, onBlur, onClick, onFocus, onKeyDown, role, tabIndex = 0, testId, title }: LinkInfoRendererProps,
  ref: Ref<HTMLAnchorElement>
): JSX.Element => {
  const { rel, target, href, outsideOfMugen, prefetch } = linkInfo;
  const isExternal = useIsExternalLink(href);
  const anchorRel = getAnchorRel(rel, target, isExternal);
  const linkProps = {
    'aria-label': ariaLabel,
    className,
    'data-testid': testId,
    href,
    onBlur,
    onClick,
    onFocus,
    onKeyDown,
    ref,
    rel: anchorRel,
    role,
    target,
    tabIndex,
    title,
    // Default to false unless explicitly set to true
    prefetch: prefetch ? prefetch : false
  };

  if (isExternal || outsideOfMugen) {
    // Prefetch doesn't exist on the native anchor element and causes hydration warnings.
    const { prefetch, ...nativeLinkAttributes } = linkProps;

    return <a {...nativeLinkAttributes}>{children}</a>;
  }

  return <NextLink {...linkProps}>{children}</NextLink>;
};

export default forwardRef(LinkInfoRenderer);
